import { CSSProperties } from "react";

interface IParams {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  background?: string;
}

export default function Button({
  onClick,
  children,
  disabled,
  background,
}: IParams) {
  const buttonStyle: CSSProperties | undefined = background
    ? { backgroundImage: `url(${background})` }
    : undefined;

  return (
    <button
      style={buttonStyle}
      onClick={onClick}
      className="custom_button"
      disabled={disabled}
    >
      {children}
    </button>
  );
}
