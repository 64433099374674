import "./_games.scss";
import data from "../../data/games.json";
import Button from "../../button/button";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { useNavigate } from "react-router-dom";
import Carousel from "../../carusel";
import { useState } from "react";

window.addEventListener("resize", function () {
  if (window.innerWidth > window.innerHeight) {
    const shadowDisplay = document.getElementById("shadow_display");
    if (shadowDisplay) {
      shadowDisplay.classList.add("active");
    }
  } else {
    const shadowDisplay = document.getElementById("shadow_display");
    if (shadowDisplay) {
      shadowDisplay.classList.remove("active");
    }
  }
});

window.addEventListener("load", function () {
  if (window.innerWidth > window.innerHeight) {
    const shadowDisplay = this.document.getElementById("shadow_display");
    if (shadowDisplay) {
      shadowDisplay.classList.add("active");
    }
  } else {
    const shadowDisplay = this.document.getElementById("shadow_display");
    if (shadowDisplay) {
      shadowDisplay.classList.remove("active");
    }
  }
});

export default function Games() {
  const [select, setSelect] = useState<any>();
  const local = "local-email";
  const customBg = "/background/level-bg.png";
  const lockImage = "/background/lock.png";
  const level = 1;
  const balance = 678;
  const lockedLevel = 2;
  const checkLogIn = localStorage.getItem(local);
  const navigate = useNavigate();

  const handleButtonClick = (url: string) => {
    console.log(select.url);
    (window as any).Android.showToast1(select.url);
  };

  const handleSetingsClick = () => {
    (window as any).Android.openSetings(true);
  };
  const handleProfileClick = () => {
    (window as any).Android.openProfile(true);
  };
  return (
    <div
      className="container games_container"
      style={{ backgroundImage: `url(/background/bg.png)` }}
    >
      <div className="shadow_display" id="shadow_display">
        <p className="text">please turn the screen over</p>
      </div>
      <div className="level_container">
        <div
          className="level_items"
          style={{ backgroundImage: `url(${customBg})` }}
        >
          <span className="balance">Balance</span>
          <span className="balance_number" id="text_balance">
            {balance}
          </span>
        </div>
        <div
          className="level_items"
          style={{ backgroundImage: `url(${customBg})` }}
        >
          <span className="text">Level</span>
          <span className="text number" id="text_level">
            {level}
          </span>
        </div>
      </div>
      <Carousel onSelect={setSelect} />
      <Button onClick={() => handleButtonClick(select.url)}>
        <img src="/background/button.png" className="button_img" alt="" />
      </Button>
      <img src="/background/man.png" className="man" alt="" />
      <div className="buton_wrap">
        <Button onClick={handleProfileClick}>
          <img
            src="/games/button1.png"
            className="custom_button_image"
            alt=""
          />
        </Button>
        <Button onClick={handleSetingsClick}>
          <img
            src="/games/button2.png"
            className="custom_button_image"
            alt=""
          />
        </Button>
      </div>
    </div>
  );
}
