import React from "react";
import "./_privacy.scss"


export default function Privacy() {

    const text = `
    Welcome to the Power Reels app ("App"), offered to you at no cost. This Privacy Policy outlines how we collect, use, and protect your information while using the App, which is provided "AS IS" without any guarantees. We are dedicated to safeguarding your privacy and providing a secure, enjoyable experience.
  
    Information Collection and Use
    When you download and use the App, we may collect certain information, including:
    
    Your device's IP address
    The pages you visit in the App, along with timestamps and duration of your visits
    The operating system of your device
    Additional usage data
    
    We may use the information collected to send you important updates, notifications, and marketing offers.
    
    To enhance your experience, we may ask for personally identifiable information, which will be used and retained in accordance with this Privacy Policy.
    
    The App may use libraries that access your approximate location to improve functionality and tailor advertising to your preferences. We respect your privacy and do not collect precise location data. You can adjust location permissions in your device settings at any time.
    
    Opt-Out Rights
    You can stop the collection of information by uninstalling the App using the standard processes available on your device or through the app marketplace.
    
    Data Retention Policy
    We will retain your data as long as you use the App and for a reasonable time thereafter. If you wish to delete your data, please contact us at game.supdeal@proton.me, and we will respond promptly.
    
    Third-Party Access
    We may share aggregated and anonymized data with external services to enhance the App and its functionality. We may also share your information with third parties as outlined in this Privacy Policy.
    
    Please be aware that the App uses third-party services, each with its own privacy policies. Below are links to the privacy policies of the third-party providers we use:
    
    Google Play Services
    
    We may disclose both user-provided and automatically collected information under the following circumstances:
    
    As required by law, such as in response to a subpoena;
    When we believe disclosure is necessary to protect our rights, ensure your safety, or investigate fraud;
    To our trusted service providers who assist us and adhere to this Privacy Policy.
    
    Children
    We do not knowingly collect information from children under 13, and the App is not intended for use by this age group. If we learn that a child under 13 has provided personal information, we will delete it immediately. Parents or guardians who become aware of such information being submitted should contact us at game.supdeal@proton.me.
    
    Security
    We take the confidentiality of your information seriously and implement physical, electronic, and procedural safeguards to protect it.
    
    Changes
    This Privacy Policy may be updated periodically. We will inform you of any changes by updating this page. Please review this Privacy Policy regularly, as continued use of the App constitutes your acceptance of any modifications.
    
    This Privacy Policy is effective as of September 30, 2024.
    
    Your Consent
    By using the App, you consent to the processing of your information as outlined in this Privacy Policy and any subsequent amendments.
    
    Contact Us
    For any questions regarding your privacy while using the App or our practices, please contact us at:
    
   
    `;


    const formattedText = text.split('\n').map((line, index) => (

<React.Fragment key={index}>
          {line.trim()}
          <br />
        </React.Fragment>
      ));

    return(
        <div className="container">
            <div className="privacy">
                <h2 className="title">Privacy Policy</h2>
                <p className="text">
                {formattedText}
                </p>
                <a href="mailto: game.supdeal@proton.me" className="link"> game.supdeal@proton.me</a>
            </div>

        </div>
    )
    
}