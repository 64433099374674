import React, { useEffect, useState } from 'react'; 
import data from "./data/games.json";

const Carousel = ({ onSelect }) => {
  const [angle, setAngle] = useState(0);
  const [highestElement, setHighestElement] = useState(8);

  const items = data;

  const step = 360 / items.length; // Кут між островами
  const radius = 180; // Радіус кола

  let startX; 
  let isDragging = false;

  const handleMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const currentX = e.pageX;
    const deltaX = currentX - startX;

    if (deltaX > 50) {
      rotateCarousel(step);
      isDragging = false;
    } else if (deltaX < -50) {
      rotateCarousel(-step);
      isDragging = false;
    }
  };

  const handleMouseUp = () => {
    isDragging = false;
  };

  const handleTouchStart = (e) => {
    isDragging = true;
    startX = e.touches[0].pageX;
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentX = e.touches[0].pageX;
    const deltaX = currentX - startX;

    if (deltaX > 50) {
      rotateCarousel(step);
      isDragging = false;
    } else if (deltaX < -50) {
      rotateCarousel(-step);
      isDragging = false;
    }
  };

  const handleTouchEnd = () => {
    isDragging = false;
  };

  const rotateCarousel = (deltaAngle) => {
    setAngle((prevAngle) => prevAngle + deltaAngle);
  };

  const findHighestElement = () => {
    let highestY = Infinity;
    let highestIdx = 0;

    // Обчислюємо координати кожного елемента
    items.forEach((_, index) => {
      const angleForIndex = index * step + angle;
      const rad = (angleForIndex * Math.PI) / 180;
      const yPos = Math.sin(rad) * radius;

      // Знаходимо елемент з найменшим значенням Y (найвищий)
      if (yPos < highestY) {
        highestY = yPos;
        highestIdx = index;
      }
    });

    // Оновлюємо стан найвищого елемента
    setHighestElement(highestIdx);
    onSelect(items[highestIdx]);
  };

  useEffect(() => {
    findHighestElement(); // Викликаємо функцію щоразу при зміні кута
  }, [angle]);

  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: "hidden",
      }}
    >
      {/* Центральний елемент (подарунок) */}
      <div
        style={{
          position: 'absolute',
          top: '80%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
          backgroundImage: `url(/background/gift-bg.png)`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '30%',
          height: '25%',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/background/gif.png" alt="Gift" style={{ width: '45%' }} />
      </div>

      {/* Острівки розміщені по колу */}
      <div
        className="carousel-orbit"
        style={{
          position: 'absolute',
          top: '90%',
          left: '45%',
          width: '100%',
          height: '100%',
          transform: `translate(-50%, -50%) rotate(${angle}deg)`,
          transition: 'transform 0.8s cubic-bezier(0.25, 0.8, 0.5, 1)',  // Плавний перехід з анімаційною кривою
          borderRadius: '50%',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {items.map((item, index) => {
          const angleStep = (360 / items.length) * index; // Кут для кожного елемента
          const x = radius * Math.cos((angleStep * Math.PI) / 180); // Розрахунок X позиції
          const y = radius * Math.sin((angleStep * Math.PI) / 180); // Розрахунок Y позиції

          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `calc(50% + ${y}px)`,
                left: `calc(50% + ${x}px)`,
                transform: `translate(-50%, -50%) rotate(${-angle}deg)`, // Компенсація повороту, щоб острівки завжди дивились вгору
                background: index === highestElement ? ' #ffff008c' : 'transparent', // Додаємо ефект для найвищого елемента
                padding: "15px 5px",
                borderRadius: "50px",
                width: '90px',
                height: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'background 0.5s ease, transform 0.8s cubic-bezier(0.25, 0.8, 0.5, 1)',  // Плавність для елементів
              }}
              className={`island${index + 1}`}
            >
              <img
                src={item.src}
                alt={item.alt}
                style={{ width: '100%', height: '100%' }}
              />
              <img src={item.image} alt="" className="island_game" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
