import { useNavigate } from "react-router-dom";
import Button from "../../button/button";
import "./_menue.scss";

export default function Menue() {
  const navigate = useNavigate();

  const handleButtonClick = (name: string) => {
    navigate(`/${name}`);
  };

  const buttonBaground = "/background/button-bg.png";

  return (
    <div
      style={{ backgroundImage: "url(/background/first-frame-bg.png)" }}
      className="container menue_container"
    >
      <img
        src="/background/dino-rotate.png"
        alt="dino image"
        className="dino_img"
      />

      <div className="button_wrap">
        <Button
          background={buttonBaground}
          onClick={() => handleButtonClick("privacy")}
        >
          Privacy
        </Button>
        <Button
          background={buttonBaground}
          onClick={() => {
            handleButtonClick("games");
            (window as any).Android.gamesButton(true);
          }}
        >
          Games
        </Button>
        <Button
          background={buttonBaground}
          onClick={() => (window as any).Android.exit(true)}
        >
          Exit
        </Button>
      </div>
    </div>
  );
}
